import React from "react"
import { Link } from "gatsby"
import Contact from "../components/contact"
import Footer from "../components/footer"
import Navbar from "../components/navbar"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const NotFoundPage = () => {

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "space.png" }) {
        childImageSharp {
          fluid(maxWidth: 2048) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <a href="#" className="scrolltop" id="scroll-top">
        <i className="bx bx-chevron-up scrolltop__icon"></i>
      </a>
      <Navbar />
      <div className="notfound__container bd-container bd-grid">
        <div className="notfound__data" data-sal="slide-down" data-sal-delay="200" data-sal-duration="400" data-sal-easing="ease">
          <p className="notfound__title">Oops!</p>
          <h1 className="notfound__subtitle">Looks like you have get lost</h1>
          <p className="notfound__message">Please navigate your space ship comeback to base</p>
          <Link to="/" className="notfound__button">Back to Homepage</Link>
        </div>
        <Img fluid={data.placeholderImage.childImageSharp.fluid} className="notfound__img"/>
      </div>
      <Contact />
      <Footer />
    </div>

  )
}

export default NotFoundPage
